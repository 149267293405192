<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <router-link 
          v-if="!serverError && status === 'NAQL_BLOCKED'" 
          :to="{ name: `${routePrefix}.edit`, params: { id } }"  
          class="btn btn-neutral btn-sm"  
          v-can:edit="permissionPrefix"
        >
          <i class="fa fa-edit"></i>
        </router-link>
      
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="image">{{ $t(`fields.image`)}}</label>
                  <files-image v-model="image" :disabled="true" :thumbnailImage="image.thumbnail"/>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="identity">{{ $t(`${transPrefix}.fields.identity`)}}</label>
                  <files-image id="identity" v-model="identity" :disabled="true" :thumbnailImage="identity.thumbnail"/>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="driving_license">{{ $t(`${transPrefix}.fields.driving_license`)}}</label>
                  <files-image id="driving_license" v-model="driving_license" :disabled="true" :thumbnailImage="driving_license.thumbnail"/>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="registration_license">{{ $t(`${transPrefix}.fields.registration_license`)}}</label>
                  <files-image id="registration_license" v-model="registration_license" :disabled="true" :thumbnailImage="registration_license.thumbnail"/>
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`)}}</label>
                  <input  v-model="name"
                          id="name"
                          type="text"
                          class="form-control"
                          name="name"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="email">{{ $t(`fields.email`)}}</label>
                  <input  v-model="email"
                          id="email"
                          type="text"
                          class="form-control"
                          name="email"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="city_name">{{ $t(`fields.city`)}}</label>
                  <input  v-model="city_name"
                          id="city_name"
                          type="text"
                          class="form-control"
                          name="city_name"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="mobile_number">{{ $t(`fields.mobile_number`)}}</label>
                  <input  v-model="mobile_number"
                          id="mobile_number"
                          type="text"
                          class="form-control"
                          name="mobile_number"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="date_of_birth">{{ $t(`fields.date_of_birth`)}}</label>
                  <input  v-model="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          class="form-control"
                          name="date_of_birth"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="nationality" class="d-block">{{ $t(`fields.nationality`)}}</label>

                  <vue-country-code id="nationality" class="w-25" v-if="loadNationality()"
                                    @onSelect="onSelectNationality"
                                    :defaultCountry="nationality"
                                    :dropdownOptions="{ disabledDialCode: true }"
                                    :disabled="true"
                  />
                  <input  :value="nationality_label"
                          type="text"
                          class="form-control w-75 d-inline-block"
                          name="nationality"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="id_number">{{ $t(`${transPrefix}.fields.id_number`)}}</label>
                  <input  v-model="id_number"
                          id="id_number"
                          type="text"
                          class="form-control"
                          name="id_number"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="id_type">{{ $t(`${transPrefix}.fields.id_type`)}}</label>
                  <select-captain-id-type v-model="id_type" :disabled="true"/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="status">{{ $t(`fields.status`)}}</label>
                  <select-captain-status v-model="status" id="status" :disabled="true"/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="working_status">{{ $t(`${transPrefix}.fields.working_status`)}}</label>
                  <select-captain-working-status v-model="working_status" :disabled="true"/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="capabilities">{{ $t(`fields.capabilities`) }}</label>
                  <capabilities-field :itemID="id" :permissionPrefix="permissionPrefix" :capabilities="capabilities"/>
                </div>
              </div>
              <div class="col-12 col-md-6" >
                <div class="form-group">
                  <label for="platform_id">{{ $t(`fields.platform`)}}</label>
                  <select-platforms v-model="platform_id" disabled />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="supplier">{{ $t(`${transPrefix}.fields.supplier`)}}</label>
                  <input  v-model="supplier"
                          id="supplier"
                          type="text"
                          class="form-control"
                          name="supplier"
                          disabled
                    >
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-6">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="ready_to_work">{{ $t(`${transPrefix}.fields.ready_to_work`)}}</label>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input type="checkbox" id="ready_to_work" v-model="ready_to_work" :disabled="true">
                      <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="connected">{{ $t(`${transPrefix}.fields.connected`)}}</label>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input type="checkbox" id="connected" v-model="connected" :disabled="true">
                      <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="suspended">{{ $t(`${transPrefix}.fields.suspended`)}}</label>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input type="checkbox" id="suspended" v-model="suspended" :disabled="true">
                      <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
                    </label>
                  </div>
                </div>
              </div>

              

            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <a class="btn btn-danger btn-sm" v-if="suspensionReasons.length>0"
                   data-target="#modal-suspension-reasons"
                   data-toggle="modal"
                   href="javascript:;"
                   v-b-tooltip.hover="{ animation: false}"
                   :title="$t('labels.cancellation_reasons')"
                >
                  <i class="fas fa-ban"></i>
                </a>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div
            aria-hidden="true"
            aria-labelledby="modal-suspension-reasons"
            class="modal fade"
            id="modal-suspension-reasons"
            role="dialog"
            tabindex="-1"
    >
      <div
              class="modal-dialog modal-dialog-centered modal-lg"
              role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">
              {{ $t("labels.suspension_reasons") }}
            </h6>
            <button
                    aria-label="Close"
                    class="close"
                    data-dismiss="modal"
                    type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <ul class="list">

                <li v-for="(suspensionReason,index) in suspensionReasons" :key="index">
                  {{suspensionReason.reason}}  <p style="color: #1496ba; font-size: 12px;">    -{{suspensionReason.suspendedBy}}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button
                    class="btn btn-primary ml-auto"
                    data-dismiss="modal"
                    type="button"
            >
              {{ $t("labels.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { CAPTAINS_SHOW as CRUD_ACTION_SHOW } from 'actions/suppliers_portal/captains';

  import capabilitiesField from "./fields/capabilities";
  const CAPTAINS_CODE="captains";
  const CRUD_CODE=`suppliers_portal.${CAPTAINS_CODE}`;
  const CRUD_PERMISSION_CODE=`supplier_${CAPTAINS_CODE}`;

  export default {
    name: "SuppliersPortalCaptainShow",
    components:{
      capabilitiesField,
    },
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CAPTAINS_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        id:this.$router.currentRoute.params.id,
        id_number: '',
        supplier: '',
        status: '',
        connected: false,
        id_type: '',
        ready_to_work: false,
        suspended: false,
        suspensionReasons: [],
        working_status:'',
        default_city_id:'',
        city_name:'',
        email: '',
        mobile_number: '',
        nationality: '',
        platform_id: '',
        nationality_label: '',
        is_nationality_load: false,
        date_of_birth: '',
        name:'',
        capabilities:[],
        serverError: '',
        image:{
          file:'',
          thumbnail:'',
        },
        driving_license:{
          file:'',
          thumbnail:'',
        },
        registration_license:{
          file:'',
          thumbnail:'',
        },
        identity:{
          file:'',
          thumbnail:'',
        },
        responseStatus:'',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus && this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.id_number=response.data.idNumber;
          this.supplier=response.data.supplier;
          this.status=response.data.status;
          this.working_status=response.data.workingStatus;
          this.default_city_id=response.data.defaultCityId;
          this.city_name=response.data.city.name;
          this.connected=response.data.connected;
          this.id_type=response.data.idType;
          this.ready_to_work=response.data.readyToWork;
          this.suspended=response.data.suspended;
          this.platform_id =  response.data.platformId;
          this.suspensionReasons=response.data.suspensionReasons;
          this.date_of_birth=response.data.dob?this.$moment(response.data.dob).format("Y-MM-DD"):"";
          this.nationality=response.data.nationality;
          this.email=response.data.user?response.data.user.email:'';
          this.mobile_number=response.data.user?response.data.user.userMobile.mobileNumber:'';
          this.name=response.data.user?response.data.user.name:'';
          this.image.thumbnail=response.data.user?(response.data.user.profilePicture?response.data.user.profilePicture.url:''):'';
          this.driving_license.thumbnail=response.data.drivingLicenseFile?response.data.drivingLicenseFile.url:'';
          this.registration_license.thumbnail=response.data.registrationFile?response.data.registrationFile.url:'';
          this.identity.thumbnail=response.data.idFile?response.data.idFile.url:'';
          this.capabilities=response.data.capabilities;

          this.is_nationality_load=!!this.nationality;
          this.responseStatus=response.status;
        }).catch(error => {
          this.loading = false
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      onSelectNationality({name, iso2, dialCode}) {
        this.nationality=iso2.toLowerCase();
        this.nationality_label=`${name} (${iso2})`;
      },
      loadNationality(){
        return this.isItemFound() && this.is_nationality_load;
      }
    }
  };
</script>
