import Vue from "vue";
import Vuex from "vuex";
import dashboard from "./modules/dashboard";
import analysis from "./modules/analysis";
import user from "./modules/user";
import auth from "./modules/auth";
import crud from "./modules/crud";
import countries from "./modules/countries";
import cities from "./modules/cities";
import zones from "./modules/zones";
import users from "./modules/users";
import banks from "./modules/banks";
import platforms from "./modules/platforms";
import captains from "./modules/captains";
import ibans from "./modules/ibans";
import fintechs from "./modules/fintechs";
import orders from "./modules/orders";
import allocation_windows from "./modules/allocation_windows";
import allocation_configs from "./modules/allocation_configs";
import system_envs from "./modules/system_envs";
import withdraw_configs from "./modules/withdraw_configs";
import withdraw_requests from "./modules/withdraw_requests";
import app_versions from "./modules/app_versions";
import roles from "./modules/roles";
import envelopes from "./modules/envelopes";
import captains_registration_requests from "./modules/captains_registration_requests";
import capabilities from "./modules/capabilities";
import profile from "./modules/profile";
import platformsPortalOrders from "./modules/platforms_portal/orders";
import socketsTracking from "./modules/sockets/tracking";
import socketsNotifications from "./modules/sockets/notifications";
import socketsStomp from "./modules/sockets/stomp";
import platformsPortalUsers from "./modules/platforms_portal/users";
import platformsPortalApps from "./modules/platforms_portal/apps";
import platformsPortalInvoices from "./modules/platforms_portal/invoices";
import platformsPortalPlatform from "./modules/platforms_portal/platform";
import platformsPortalBranches from "./modules/platforms_portal/branches";
import suppliers from "./modules/suppliers";
import suppliersPortalCatpains from "./modules/suppliers_portal/captains";
import suppliersPortalCaptainRegistrationRequests from "./modules/suppliers_portal/captain_registration_requests";
import suppliersPortalCapabilities from "./modules/suppliers_portal/capabilities";
import suppliersPortalSupplier from "./modules/suppliers_portal/supplier";
import suppliersPortalReports from "./modules/suppliers_portal/reports";
import suppliersPortalOrders from "./modules/suppliers_portal/orders";
import suppliersPortalTracking from "./modules/suppliers_portal/tracking";
import tracking from "./modules/tracking";
import captainBonuses from "./modules/captain_bonuses";
import labeledPricingRuleGroups from "./modules/labeled_pricing_rule_groups";
import wallets from "./modules/wallets";
import walletTransactions from "./modules/wallet_transactions";
import referralPrograms from "./modules/referral_programs";
import tickets from "./modules/tickets";
import auditLogs from "./modules/audit_logs";

import sharedLists from "./modules/shared_lists";
import captainWorkingSummaries from "./modules/captain_working_summaries";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    isLoading: false,
    threadCount: 0,
  },
  getters: {
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    START_LOADING: (state, withLoading) => {
      if (withLoading !== false) {
        state.threadCount++;
        if (state.threadCount == 1) {
          state.isLoading = true;
        }
      }
    },
    STOP_LOADING: (state, withLoading) => {
      if (withLoading !== false) {
        state.threadCount--;
        if (state.threadCount == 0) {
          state.isLoading = false;
        }
      }
    },
  },
  modules: {
    dashboard,
    analysis,
    user,
    auth,
    crud,
    countries,
    cities,
    zones,
    users,
    banks,
    app_versions,
    platforms,
    captains,
    ibans,
    fintechs,
    orders,
    allocation_windows,
    allocation_configs,
    system_envs,
    withdraw_configs,
    withdraw_requests,
    roles,
    envelopes,
    capabilities,
    captains_registration_requests,
    profile,
    platformsPortalOrders,
    socketsTracking,
    socketsNotifications,
    socketsStomp,
    platformsPortalUsers,
    platformsPortalApps,
    platformsPortalInvoices,
    platformsPortalPlatform,
    platformsPortalBranches,
    suppliersPortalSupplier,
    suppliers,
    suppliersPortalCatpains,
    suppliersPortalCaptainRegistrationRequests,
    suppliersPortalCapabilities,
    suppliersPortalReports,
    suppliersPortalOrders,
    suppliersPortalTracking,
    tracking,
    captainBonuses,
    labeledPricingRuleGroups,
    wallets,
    walletTransactions,
    referralPrograms,
    tickets,
    auditLogs,
    sharedLists,
    captainWorkingSummaries,
  },
  strict: debug,
});
