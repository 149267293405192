<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div v-if="serverError" class="alert alert-danger">
                <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!serverError">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="image">{{ $t(`fields.image`) }}</label>
                <files-image v-model="image" :disabled="true" :thumbnailImage="image.thumbnail" />
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="identity">{{ $t(`fields.identity`) }}</label>
                <files-image id="identity" v-model="identity" :disabled="true" :thumbnailImage="identity.thumbnail" />
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="driving_license">{{
                  $t(`fields.driving_license`)
                  }}</label>
                <files-image id="driving_license" v-model="driving_license" :disabled="true"
                  :thumbnailImage="driving_license.thumbnail" />
              </div>
            </div>
          </div>
          <div class="row" v-if="!serverError">
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="name">{{ $t(`fields.name`) }}</label>
                <input v-model="name" id="name" type="text" class="form-control" name="name" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="mobile_number">{{
                  $t(`fields.mobile_number`)
                  }}</label>
                <input v-model="mobile_number" id="mobile_number" type="text" class="form-control" name="mobile_number"
                  disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="city_name">{{ $t(`fields.city`) }}</label>
                <input v-model="city_name" id="city_name" type="text" class="form-control" name="city_name" disabled />
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="email">{{ $t(`fields.country`) }}</label>
                <input v-model="country_name" id="country" type="text" class="form-control" name="country" disabled />
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="date_of_birth">{{
                  $t(`fields.date_of_birth`)
                  }}</label>
                <input v-model="date_of_birth" id="date_of_birth" type="date" class="form-control" name="date_of_birth"
                  disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="nationality" class="d-block">{{
                  $t(`fields.nationality`)
                  }}</label>
                <vue-country-code id="nationality" class="w-25" v-if="loadNationality()" @onSelect="onSelectNationality"
                  :defaultCountry="nationality" :dropdownOptions="{ disabledDialCode: true }" :disabled="true" />
                <input :value="nationality_label" type="text" class="form-control w-75 d-inline-block"
                  name="nationality" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="id_number">{{ $t(`fields.id_number`) }}</label>
                <input v-model="id_number" id="id_number" type="text" class="form-control" name="id_number" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="status">{{ $t(`fields.status`) }}</label>
                <input v-model="status" id="status" type="text" class="form-control" name="status" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="platform_id">{{
                  $t(`fields.preferred_platform`)
                  }}</label>
                <input v-model="preferred_platform" id="preferred_platform" type="text" class="form-control"
                  name="preferred_platform" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="id_type">{{ $t(`fields.bank`) }}</label>
                <input v-model="bank" id="bank" type="text" class="form-control" name="bank" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="iban">{{
                  $t(`fields.iban`)
                  }}</label>
                <input v-model="iban" id="iban" type="text" class="form-control" name="iban" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="iban_name">{{
                  $t(`fields.iban_name`)
                  }}</label>
                <input v-model="iban_name" id="iban_name" type="text" class="form-control" name="iban_name" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="fintech_account_id">{{
                  $t(`fields.fintech_account_id`)
                  }}</label>
                <input v-model="fintech_account_id" id="fintech_account_id" type="text" class="form-control"
                  name="fintech_account_id" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="preferred_vertical">{{
                  $t(`fields.preferred_vertical`)
                  }}</label>
                <input v-model="preferred_vertical" id="preferred_vertical" type="text" class="form-control"
                  name="preferred_vertical" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="vehicle_plate_number">{{ $t(`fields.vehicle_plate_number`) }}</label>
                <input v-model="vehicle_plate_number" id="vehicle_plate_number" type="text" class="form-control" name="vehicle_plate_number" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="vehicle_sequence_number">{{ $t(`fields.vehicle_sequence_number`) }}</label>
                <input v-model="vehicle_sequence_number" id="vehicle_sequence_number" type="text" class="form-control" name="vehicle_sequence_number" disabled />
              </div>
            </div>
          </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer">
          <div class="row">
            <div class="col text-right">
              <router-link :to="{ name: `${routePrefix}.index` }" class="btn btn-neutral btn-sm">
                <i class="fa fa-arrow-left"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CAPTAIN_REGISTRATION_REQUEST_SHOW as CRUD_ACTION_SHOW } from "actions/suppliers_portal/captain_registration_requests";

const CAPTAINS_CODE = "captain_registration_requests";
const CRUD_CODE = `suppliers_portal.${CAPTAINS_CODE}`;
const CRUD_PERMISSION_CODE = `supplier_${CAPTAINS_CODE}`;

export default {
  name: "SuppliersPortalCaptainRegistrationRequestShow",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CAPTAINS_CODE,
      permissionPrefix: CRUD_PERMISSION_CODE,
      id: this.$router.currentRoute.params.id,
      id_number: "",
      status: "",
      country_name: "",
      city_name: "",
      mobile_number: "",
      nationality: "",
      preferred_platform: "",
      nationality_label: "",
      is_nationality_load: false,
      date_of_birth: "",
      name: "",
      bank: "",
      iban: "",
      iban_name: "",
      fintech_account_id: "",
      preferred_vertical: "",
      vehicle_plate_number: "",
      vehicle_sequence_number: "",
      serverError: "",
      image: {
        file: "",
        thumbnail: "",
      },
      driving_license: {
        file: "",
        thumbnail: "",
      },
      identity: {
        file: "",
        thumbnail: "",
      },
      responseStatus: "",
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    isItemFound() {
      return this.responseStatus && this.responseStatus !== 404;
    },
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          id: this.id,
        })
        .then((response) => {
          console.log("response :", response.data);
          const {
            preferredPlatform,
            country,
            city,
            bank,
            iban,
            ibanName,
            fintechAccountId,
            preferredVertical,
            vehiclePlateNumber,
            vehicleSequenceNumber
          } = response.data;

          this.name = response.data.name;
          this.id_number = response.data.idNumber;
          this.status = response.data.status;
          this.country_name = country
            ? `${country.id}: ${country.name} - ${country.nameAr}`
            : "";
          this.city_name = city
            ? `${city.id}: ${city.name} - ${city.nameAr}`
            : "";
          this.preferred_platform = preferredPlatform
            ? `${preferredPlatform.id}: ${preferredPlatform.name} - ${preferredPlatform.nameAr}`
            : "";
          this.bank = bank ? `${bank.id}: ${bank.name}` : "";
          this.date_of_birth = response.data.dob
            ? this.$moment(response.data.dob).format("Y-MM-DD")
            : "";
          this.nationality = response.data.nationality;
          this.mobile_number = response.data.mobileNumber
            ? response.data.mobileNumber
            : "";
          this.image.thumbnail = response.data.profilePicFile
            ? response.data.profilePicFile.url
              ? response.data.profilePicFile.url
              : ""
            : "";
          this.driving_license.thumbnail = response.data.drivingLicenseFile
            ? response.data.drivingLicenseFile.url
            : "";
          this.identity.thumbnail = response.data.idFile
            ? response.data.idFile.url
            : "";
          this.is_nationality_load = !!this.nationality;
          this.responseStatus = response.status;
          this.iban = iban ? iban : "";
          this.iban_name = ibanName ? ibanName : "";
          this.fintech_account_id = fintechAccountId ? fintechAccountId : "";
          this.preferred_vertical = preferredVertical ? preferredVertical : "";
          this.vehicle_plate_number = vehiclePlateNumber
            ? vehiclePlateNumber
            : "";
          this.vehicle_sequence_number = vehicleSequenceNumber
            ? vehicleSequenceNumber
            : "";
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    onSelectNationality({ name, iso2, dialCode }) {
      this.nationality = iso2.toLowerCase();
      this.nationality_label = `${name} (${iso2})`;
    },
    loadNationality() {
      return this.isItemFound() && this.is_nationality_load;
    },
  },
};
</script>
