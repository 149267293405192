import {
  CAPTAIN_REGISTRATION_REQUEST_LIST as CRUD_ACTION_LIST,
  CAPTAIN_REGISTRATION_REQUEST_SHOW as CRUD_ACTION_SHOW,
} from "../../actions/suppliers_portal/captain_registration_requests";
import axioses from "@/axios/config";

const routePrefix = "captain_registration_requests";

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", params.withLoading, { root: true });

      axioses.supplierPortal
        .get(`/${routePrefix}`, { params })
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", params.withLoading, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", params.withLoading, { root: true });
          reject(error);
        });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", null, { root: true });
      axioses.supplierPortal
        .get(`/${routePrefix}/${params.id}`)
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", null, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
