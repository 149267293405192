<template>
  <div class="row">
    <div class="col">
      <card-container :title="$router.currentRoute.meta.title" :headers="tableHeaders" :lastPage="lastPage"
        :totalElements="totalElements" :currentPage="getCurrentPage()" :clickHandler="pagination">
        <template v-slot:card-header>
          <router-link :to="{ name: `${routePrefix}.create` }" class="btn btn-primary btn-sm"
            v-can:add="permissionPrefix">
            <i class="fa fa-plus"></i>
          </router-link>
        </template>

        <template v-slot:card-header-search>
          <search v-model="lastPage" :listHandler="getList" style="margin-top: 30px" />
        </template>

        <template v-slot:table-rows>
          <tr v-for="(item, index) in items" :key="item.id">
            <td>
              <div class="media align-items-center">
                <a v-if="getImageThumbnail(item)" href="javascript:;" class="avatar rounded-circle">
                  <img alt="Image placeholder" :src="getImageThumbnail(item)" />
                </a>
                <div class="media-body">
                  <span class="name mb-0 text-sm"></span>
                </div>
              </div>
            </td>
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.idNumber }}</td>
            <td>{{ item.mobileNumber }}</td>
            <td>{{ `${item.city.id}-${item.city.name}` }}</td>
            <td>{{ `${item.country.id}-${item.country.name}` }}</td>
            <td>{{ item.preferredPlatformId }}</td>
            <td>{{ item.status }}</td>
            <td class="text-direction-ltr">
              {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }}
            </td>
            <table-item-actions :actions="tableItemActions" :params="{ id: item.id }" />
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t("messages.no_result_found") }}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  CAPTAIN_REGISTRATION_REQUEST_LIST as CRUD_ACTION_LIST,
} from "actions/suppliers_portal/captain_registration_requests";
import search from "./partials/search";
const CAPTAINS_CODE = "captain_registration_requests";
const CRUD_CODE = `suppliers_portal.${CAPTAINS_CODE}`;
const CRUD_PERMISSION_CODE = `supplier_${CAPTAINS_CODE}`;
export default {
  name: "SuppliersPortalCaptainIndex",
  components: {
    search,
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CAPTAINS_CODE,
      permissionPrefix: CRUD_PERMISSION_CODE,
      tableHeaders: [
        {
          title: `fields.image`,
        },
        {
          title: `fields.id`,
        },
        {
          title: `fields.name`,
        },
        {
          title: `fields.id_number`,
        },
        {
          title: `fields.mobile_number`,
        },
        {
          title: `fields.city`,
        },
        {
          title: `fields.country`,
        },
        {
          title: `fields.preferred_platform_id`,
        },
        {
          title: `fields.status`,
        },
        {
          title: `fields.created_at`,
        },
        {
          title: `fields.actions`,
        },
      ],
      tableItemActions: [
        {
          code: "show",
          route_name: `${CRUD_CODE}.show`,
          can: `supplier_captains_can_view`,
        },
      ],
      items: [],
      perPage: 20,
      totalElements: 0,
      lastPage: 0,
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters([
      "getSearchFilters",
      "getSearchFiltersIfExists",
      "getSearchFiltersSensitized",
    ]),
  },
  methods: {
    getList() {
      this.$store
        .dispatch(CRUD_ACTION_LIST, {
          pageId: this.getCurrentPage() - 1,
          ...this.getSearchFiltersSensitized,
        })
        .then((response) => {
          this.items = response.data.data;
          this.perPage = response.data.perPage;
          this.totalElements = response.data.totalElements;
          this.lastPage = response.data.pageCount;
        });
    },
    pagination(page) {
      if (page !== this.getCurrentPage()) {
        this.$router.push({
          name: `${this.routePrefix}.index`,
          query: {
            page,
            ...this.getSearchFiltersIfExists,
          },
        });
        this.getList();
      }
    },
    getCurrentPage() {
      let currentPage = this.$route.query.page ?? 1;
      return parseInt(currentPage >= 1 ? currentPage : 1);
    },
    getImageThumbnail(item) {
      let image = "";
      if (item.profilePicFile && item.profilePicFile.url) {
        image = item.profilePicFile.url;
      }
      return image;
    },
  },
};
</script>
