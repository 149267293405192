<template>
    <nav class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white" id="sidenav-main">
        <div class="scrollbar-inner">
            <!-- Brand -->
            <div class="sidenav-header  align-items-center">
                <TopNavbarSideMenu tag="div"/>
                <a class="navbar-brand" href="javascript:void(0)">
                    <img src="@/assets/logo.png" class="navbar-brand-img" alt="...">
                </a>
            </div>
            <div class="navbar-inner pt-2">
                <!-- Collapse -->
                <div class="collapse navbar-collapse" id="sidenav-collapse-main">
                    <!-- Nav items -->
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <router-link active-class="active" :to="{ name: 'dashboard.index' }" class="nav-link">
                                <i class="ni ni-tv-2 text-primary"></i>
                                <span class="nav-link-text">{{ $t('dashboard.title') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can(`supplier_tracking`,`view`)">
                            <router-link active-class="active" :to="{ name: 'suppliers_portal.tracking.index' }" class="nav-link">
                                <i class="fa fa-map-signs text-gray"></i>
                                <span class="nav-link-text">{{ $t('tracking.title') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can(`supplier_orders`,`view`)">
                            <router-link active-class="active" :to="{ name: 'suppliers_portal.orders.index' }" class="nav-link">
                                <i class="fa fa-shopping-cart text-green"></i>
                                <span class="nav-link-text">{{ $t('orders.title') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can(`partner_orders`,`view`)">
                            <router-link active-class="active" :to="{ name: 'platforms_portal.orders.index' }" class="nav-link">
                                <i class="fa fa-shopping-cart text-green"></i>
                                <span class="nav-link-text">{{ $t('orders.title') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can(`partner_orders`,`create`)">
                            <router-link active-class="active" :to="{ name: 'platforms_portal.orders.create' }" class="nav-link">
                                <i class="fa fa-cart-plus text-blue"></i>
                                <span class="nav-link-text">{{ $t('labels.manual_dispatch') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can(`partner_invoice`,`view`)">
                            <router-link active-class="active" :to="{ name: 'platforms_portal.invoices.index' }" class="nav-link">
                                <i class="fas fa-file-invoice text-yellow"></i>
                                <span class="nav-link-text">{{ $t('platforms.invoices.title') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can(`partner_platform`,`view`)">
                             <router-link active-class="active" :to="{ name: 'platforms_portal.platform.show' }" class="nav-link">
                                 <i class="fa fa-store-alt  text-gray-dark"></i>
                                 <span class="nav-link-text">{{ $t('fields.platform') }}</span>
                             </router-link>
                        </li>
                        <li class="nav-item" v-if="can(`partner_branches`,`view`)">
                            <router-link active-class="active" :to="{ name: 'platforms_portal.branches.index' }" class="nav-link">
                                <i class="fa fa-store text-teal"></i>
                                <span class="nav-link-text">{{ $t('platforms.branches.title') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can(`supplier_captains`,`view`)">
                            <router-link active-class="active" :to="{ name: 'suppliers_portal.captains.index' }" class="nav-link">
                                <i class="fa fa-truck text-blue"></i>
                                <span class="nav-link-text">{{ $t('captains.title') }}</span>
                            </router-link>
                        </li>


                        <li class="nav-item" v-if="can(`supplier_captains`,`view`)">
                            <router-link active-class="active" :to="{ name: 'suppliers_portal.captain_registration_requests.index' }" class="nav-link">
                                <i class="fa fa-file text-pink"></i>
                                <span class="nav-link-text">{{ $t('captain_registration_requests.title') }}</span>
                            </router-link>
                        </li>


                        <li class="nav-item" v-if="can(`supplier_supplier`,`view`)">
                            <router-link active-class="active" :to="{ name: 'suppliers_portal.supplier.show' }" class="nav-link">
                                <i class="fa fa-charging-station text-gray-dark"></i>
                                <span class="nav-link-text">{{ $t('fields.supplier') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can(`supplier_report`,`view`)">
                            <router-link active-class="active" :to="{ name: 'suppliers_portal.reports.index' }" class="nav-link">
                                <i class="fas fa-file-invoice text-red"></i>
                                <span class="nav-link-text">{{ $t('suppliers.reports.title') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can(`partner_users`,`view`)">
                            <router-link active-class="active" :to="{ name: 'platforms_portal.users.index' }" class="nav-link">
                                <i class="fa fa-users text-orange"></i>
                                <span class="nav-link-text">{{ $t('users.title') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="can(`partner_apps`,`view`)">
                            <router-link active-class="active" :to="{ name: 'platforms_portal.apps.index' }" class="nav-link">
                                <i class="fa fa-mobile-alt text-dark"></i>
                                <span class="nav-link-text">{{ $t('platforms.apps.title') }}</span>
                            </router-link>
                        </li>

<!--                        <li class="nav-item">-->
<!--                            <router-link :to="{ name: 'cities.index' }" class="nav-link" >-->
<!--                                <i class="fa fa-city text-pink"></i>-->
<!--                                <span class="nav-link-text">{{ $t('cities.title') }}</span>-->
<!--                            </router-link>-->
<!--                        </li>-->

<!--                        <li class="nav-item">-->
<!--                            <router-link :to="{ name: 'zones.index' }" class="nav-link" >-->
<!--                                <i class="fa fa-search-location text-red"></i>-->
<!--                                <span class="nav-link-text">{{ $t('zones.title') }}</span>-->
<!--                            </router-link>-->
<!--                        </li>-->
                    </ul>
                </div>
            </div>
        </div>
    </nav>

</template>

<script>
import $ from "jquery";
import "js-cookie";
import "jquery.scrollbar";
import "jquery-scroll-lock";
import TopNavbarSideMenu from "./partials/TopNavbarSideMenu";
export default {
    name: "SideMenu",
    components: {TopNavbarSideMenu},
    mounted() {
        $('.scrollbar-inner,.scrollbar-outer').scrollbar().scrollLock();
    },
    methods:{
        isActiveMenu(code){
            let isActive=false;
            switch (code) {
                case 'settings':
                    isActive=this.$route.path.search(/allocation_configs|roles|withdraw_configs/i)!==-1;
                    break;
            }
            return isActive;
        }
    }
}
</script>

<style scoped>

</style>
